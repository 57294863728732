import React from "react";
import {SEO_TYPE} from "../../constant";
import SeoElement from "../SeoElement"

const data = [
    {
        type: SEO_TYPE.H1,
        heading: "Why cloud computing with Datum Consulting NZ?"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "As of 2016, IDG Enterprise found that 70% of U.S. " +
            "companies and organizations have moved to the Cloud is " +
            "some capacity if not completely. At Datum Consulting, we the " +
            "risk out of the cloud by following an agnostic " +
            "approach that ensures we take time to learn your business."
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Though partnerships, proven methodologies and decades of collective " +
            "experience in Cloud engineering and delivery, we help organizations " +
            "plan for cloud transformation, integrate in hybrid environments, navigate " +
            "legacy systems to innovate and create new competitive advantages."
    },
    {
        type: SEO_TYPE.H3,
        heading: "SECURE"
    },
    {
        type: SEO_TYPE.H3,
        heading: "SCALABLE"
    },
    {
        type: SEO_TYPE.H3,
        heading: "FLEXIBLE"
    },
    {
        type: SEO_TYPE.H3,
        heading: "INTEGRATED"
    },
    {
        type: SEO_TYPE.INTERNAL_LINK,
        to: "/contact/",
        title: "TALK ABOUT CLOUD WITH US",
        label: "TALK ABOUT CLOUD WITH US"
    },
    // services offering
    {
        type: SEO_TYPE.H2,
        heading: "Our cloud services offering"
    },
    // services offering: Cloud Transformation
    {
        type: SEO_TYPE.H3,
        heading: "Cloud Transformation"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Through partnerships with leading cloud vendors, Datum Consulting " +
            "can take you from the very beginning of your cloud journey, be it " +
            "public, private or hybrid cloud. Our services include:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Cloud readiness assessment", "Cloud strategy and road mapping"]
    },
    // services offering: Architecture and Design
    {
        type: SEO_TYPE.H3,
        heading: "Architecture and Design"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We will team up with your business and technology stakeholders to " +
            "help define the objectives which align with people, business, and " +
            "technology. Our certified Architects then analyze the requirements " +
            "as they stands for today and into the future, and present you with " +
            "secure, resilient, cost effective, and Compliance-ready solutions."
    },
    // services offering: API and Integration
    {
        type: SEO_TYPE.H3,
        heading: "API and Integration"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We understand that technology and business must be integrated to " +
            "generate new opportunities for your organization in the new digital " +
            "age. Our ability to develop API strategies, connect data backed " +
            "systems, and navigating complex hybrid platforms with legacy and " +
            "cloud system can help you leverage existing assets to innovate."
    },
    // services offering: Cloud infrastructure automation
    {
        type: SEO_TYPE.H3,
        heading: "Cloud infrastructure automation"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Efficient provisioning and traceable infrastructure " +
            "can help you achieve " +
            "high cost effectiveness, high availability, and resiliency. Datum " +
            "Consulting will provide you with the knowledge needed " +
            "to implement modern infrastructure " +
            "automation, containers, and configuration management " +
            "tools to alleviate complexity of " +
            "orchestrating the cloud."
    },
    // services offering: Security Audit and Threat Monitoring
    {
        type: SEO_TYPE.H3,
        heading: "Security Audit and Threat Monitoring"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Security audit and threat monitoring should be ongoing activities. Our " +
            "consulting services will help you identify and rededicate weak spots " +
            "across your cloud infrastructure and information services:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Infrastructure security assessment", "Remediation plan"]
    },
    // services offering: Compliance Audit
    {
        type: SEO_TYPE.H3,
        heading: "Compliance Audit"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We work together to ensure your infrastructure and applications meet " +
            "specific compliance requirements:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: [
            "General Data Projection (GPDR)",
            "Healthcare (HIPAA)",
            "Payments (PCI-DSS)",
            "Insurance (SOC) and Finance (SOX)"
        ]
    },
    // services offering: Automation testing
    {
        type: SEO_TYPE.H3,
        heading: "Automation testing"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "QA service is an integral part in any of of our software development " +
            "project. During the course of development cycle, we will setup and " +
            "perform various types of testing to detect error and verify the " +
            "functionality of the end products " +
            "tests are within our capabilities."
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Unit tests", "Integration tests", "End-to-end tests", "Browser automation"]
    },
    // services offering: Cost optimization
    {
        type: SEO_TYPE.H3,
        heading: "Cost optimization"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We work together to ensure your infrastructure and applications meet " +
            "specific compliance requirements:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: [
            "Right sizing and TCO analysis",
            "Reservation and commitment plan",
            "Tuning architecture for cost reduction"
        ]
    },
    // services offering: DevOps & CI/CD implementation
    {
        type: SEO_TYPE.H3,
        heading: "DevOps & CI/CD implementation"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Our DevOps team has experience implementing with various " +
            "Continuous Integration and Continuous Delivery toolset, complex " +
            "integration tests, and end-to-end test. We bring value to our " +
            "organization by supporting internal teams to speeding up your " +
            "software delivery cycle, building up automated testing practices, " +
            "enforcing code standard, and improving overall code quality."
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Unit tests", "Integration tests", "End-to-end tests", "Browser automation"]
    },
    // services offering: Monitoring and logging
    {
        type: SEO_TYPE.H3,
        heading: "Monitoring and logging"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Utilizing the rich ecosystem of application and infrastructure " +
            "monitoring, we can help you architect and implement customized " +
            "monitoring solutions best fit for your needs:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: [
            "Visualization, bare Host and Containers monitoring",
            "Cloud infrastructure monitoring", "Network monitoring"
        ]
    },
    // list of Platform
    {
        type: SEO_TYPE.H3,
        heading: "Cloud platform"
    },
    {
        type: SEO_TYPE.H4,
        heading: "Amazon Web Services (AWS)"
    },
    {
        type: SEO_TYPE.H4,
        heading: "Microsoft Azure"
    },
    {
        type: SEO_TYPE.H4,
        heading: "Google Cloud Platform (GCP)"
    },
    {
        type: SEO_TYPE.H4,
        heading: "IBM Cloud"
    },
    {
        type: SEO_TYPE.H4,
        heading: "MuleSoft Anypoint"
    },
    {
        type: SEO_TYPE.H4,
        heading: "Redhat OpenShift"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://linkedin.com/company/datumconsultingnz",
        title: "Linkedin",
        label: "Linkedin"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://twitter.com/datumhqNZ",
        title: "Twitter",
        label: "Twitter"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://www.facebook.com/DatumConsultingNZ",
        title: "Facebook",
        label: "Facebook"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://medium.com/datum-consulting-nz",
        title: "Medium",
        label: "Medium"
    }
];

const FakeContent = () => (
    <div
        style={{
            backgroundColor: "white",
            color: "transparent",
            height: "100vh",
            width: "100vw",
            position: "relative",
            top: "0",
            left: "0",
        }}
    >
        {data.map((element, index) => <SeoElement key={index} {...element}/>)}
    </div>
);

export default FakeContent;