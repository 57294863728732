import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Loadable from "react-loadable";
import Loading from "../components/Loading";
import FakeContent from "../components/cloud/FakeContent";

const Nav = Loadable({
  loader: () => import("../components/SharedComponents/Nav"),
  loading: FakeContent,
});
const Header = Loadable({
  loader: () => import("../components/cloud/Header"),
  loading: Loading,
});
const CloudServices = Loadable({
  loader: () => import("../components/cloud/WhyCloud"),
  loading: Loading,
});
const OurServices = Loadable({
  loader: () => import("../components/cloud/OurOfferings"),
  loading: Loading,
});
const Platform = Loadable({
  loader: () => import("../components/SharedComponents/Platform"),
  loading: Loading,
});
const ContactForm = Loadable({
  loader: () => import("../components/SharedComponents/ContactFormH1"),
  loading: Loading,
});
const SubscribeFooter = Loadable({
  loader: () => import("../components/SharedComponents/SubscribeFooter"),
  loading: Loading,
});
const Footer = Loadable({
  loader: () => import("../components/SharedComponents/Footer"),
  loading: Loading,
});

export default () => {
  return (
    <Layout>
      <SEO
        title="Cloud Services | Datum Consulting NZ"
        description={
          "Our Cloud Services include cloud transformation, " +
          "architecture and design, API, integration, infrastructure automation, " +
          "automation testing, security audit, compliance audit, threat monitoring, " +
          "cost optimization, DevOps, continuous integration, continuous delivery, " +
          "monitoring and logging on AWS and Azure."
        }
        keywords={[
          `aws`,
          `azure`,
          `cloud architecture`,
          `API`,
          `integration`,
          `infrastructure automation`,
          `security audit`,
          `threat monitoring`,
          `cost optimization`,
          `devops`,
          `continuous integration`,
          `continuous delivery`,
          `cloud consulting`,
          `cloud monitoring`,
          `cloud logging`,
        ]}
        pathname="cloud"
      />
      <Nav />
      <Header />
      <CloudServices />
      <OurServices />
      <Platform />
      <ContactForm />
      <SubscribeFooter />
      <Footer />
    </Layout>
  );
};
